<template>
  <div v-if="dictionary && Object.keys(dictionary).length > 0">
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <a
                  @click="cancelAdd"
                  to="/service"
                  class="button xbtn is-medium is-transparent"
                >
                  <icon name="x"></icon>
                </a>
                <h2 class="title" v-if="isNew">Nowe zlecenie serwisowe</h2>
                <h2 class="title" v-else>Edytuj zlecenie serwisowe</h2>
              </div>
              <div class="buttons level-right" v-if="online">
                <button
                  v-if="
                    calendar && calendar.content !== '' && calendar.is_active
                  "
                  class="button is-medium is-secondary"
                  type="button"
                  @click="isModalActive = !isModalActive"
                >
                  {{ dictionary["calendar"] }}
                </button>
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="
            errors['customer'] && errors['customer'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_customer_name_field_label"]
              }}</label>
              <span
                v-if="errors['customer'] && errors['customer'].length > 0"
                class="help is-danger"
                >{{ errors["customer"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <ValidationProvider rules="required" name="customer" slim>
              <button
                v-if="
                  Object.keys(customer).length === 0 &&
                  customer.constructor === Object
                "
                @click.prevent="chooseCustomer"
                class="button is-medium is-light likeinput"
                data-value=""
                data-label=""
                data-label-novalue="Wybierz"
              >
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>
              <button
                v-else
                @click.prevent="chooseCustomer"
                class="button is-medium is-light likeinput"
                :data-value="customer.name"
                :data-label="customer.name"
                data-label-novalue="Wybierz"
              >
                <input
                  style="display: none"
                  v-model="customer.id"
                  type="text"
                />
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>
            </ValidationProvider>
          </div>
        </div>
        <div
          v-if="
            Object.keys(customer).length !== 0 &&
            customer.constructor === Object
          "
          :class="
            errors['customer'] && errors['customer'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div
            class="is-hidden-mobile column ym-column is-3-tablet is-12-mobile"
          >
            <div class="ym-label">&nbsp;</div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <textarea
                  class="textarea is-medium is-uppercase"
                  v-model="customerInfo"
                  type="text"
                  placeholder=""
                  readonly="readonly"
                >
                </textarea>
              </div>
              <div v-if="customer.is_no_payment" class="is-info">
                Zlecenie tylko i wyłącznie za gotówke lub przedpłata.
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['operator'] && errors['operator'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_operator_name_field_label"]
              }}</label>
              <span
                v-if="errors['operator'] && errors['operator'].length > 0"
                class="help is-danger"
                >{{ errors["operator"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <ValidationProvider rules="required" name="operator" slim>
              <button
                v-if="
                  Object.keys(operator).length === 0 &&
                  operator.constructor === Object
                "
                @click.prevent="chooseOperator"
                class="button is-medium is-light likeinput"
                data-value=""
                data-label=""
                data-label-novalue="Wybierz"
              >
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>
              <button
                v-else
                @click.prevent="chooseOperator"
                class="button is-medium is-light likeinput"
                :data-value="operator.lastname + ' ' + operator.firstname"
                :data-label="operator.lastname + ' ' + operator.firstname"
                data-label-novalue="Wybierz"
              >
                <input
                  style="display: none"
                  v-model="operator.id"
                  type="text"
                />
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>
            </ValidationProvider>
          </div>
        </div>
        <div
          :class="
            errors['device'] && errors['device'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_device_type_field_label"]
              }}</label>
              <span
                v-if="errors['device'] && errors['device'].length > 0"
                class="help is-danger"
                >{{ errors["device"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <ValidationProvider rules="required" name="device" slim>
              <button
                v-if="
                  Object.keys(device).length === 0 &&
                  device.constructor === Object
                "
                @click.prevent="chooseDevice"
                class="button is-medium is-light likeinput"
                data-value=""
                data-label=""
                data-label-novalue="Wybierz"
              >
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>
              <button
                v-else
                @click.prevent="chooseDevice"
                class="button is-medium is-light likeinput"
                :data-value="
                  device.type + ': ' + device.name + '/' + device.serial
                "
                :data-label="
                  device.type + ': ' + device.name + '/' + device.serial
                "
                data-label-novalue="Wybierz"
              >
                <input style="display: none" v-model="device.id" type="text" />
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>
            </ValidationProvider>
          </div>
        </div>
        <div
          v-if="
            Object.keys(device).length !== 0 && device.constructor === Object
          "
          :class="
            errors['device_address'] && errors['device_address'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
          style="display: none"
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">Miejsce postoju produktu</label>
              <p>Podaj pełen adres postoju</p>
              <span
                v-if="
                  errors['device_address'] &&
                  errors['device_address'].length > 0
                "
                class="help is-danger"
                >{{ errors["device_address"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control has-icons-right is-clearfix">
                <ValidationProvider name="device_address">
                  <input
                    name="device_address"
                    v-model="order.device_address"
                    class="input is-medium is-uppercase"
                  />
                  <span
                    @click="clearDeviceAddress"
                    class="icon is-right is-clickable"
                    ><i class="mdi mdi-close-circle mdi-24px"></i
                  ></span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['repared_at'] && errors['repared_at'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_repared_at_field_label"]
              }}</label>
              <span
                v-if="errors['repared_at'] && errors['repared_at'].length > 0"
                class="help is-danger"
                >{{ errors["repared_at"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="repared_at">
                  <b-datepicker
                    :mobile-native="false"
                    name="repared_at"
                    v-model="repared_at"
                    placeholder="Wybierz"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    trap-focus
                  >
                  </b-datepicker>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['is_warranty'] && errors['is_warranty'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column totop is-3">
            <div class="ym-label">
              <label for="f_dm_company">{{
                dictionary["table_order_is_warranty_field_label"]
              }}</label>
              <span
                v-if="errors['is_warranty'] && errors['is_warranty'].length > 0"
                class="help is-danger"
                >{{ errors["is_warranty"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="ym-rowbor">
              <div class="level ym-rowbor-item">
                <div class="ym-radios w150px">
                  <label>
                    <ValidationProvider rules="required" name="is_warranty">
                      <input
                        type="radio"
                        name="is_warranty"
                        v-model="order.is_warranty"
                        :value="true"
                      />
                      <span>Tak</span>
                    </ValidationProvider>
                  </label>
                  <label>
                    <ValidationProvider rules="required" name="is_warranty">
                      <input
                        type="radio"
                        name="is_warranty"
                        v-model="order.is_warranty"
                        :value="false"
                      />
                      <span>Nie</span>
                    </ValidationProvider>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="order.is_warranty"
          class="columns is-mobile is-variable is-3 is-multiline"
        >
          <div class="column ym-column totop is-3">
            <div class="ym-label">
              <label for="f_dm_company">{{
                dictionary["table_order_warranty_status_id_field_label"]
              }}</label>
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="warranty_status_id">
                  <select
                    name="warranty_status_id"
                    v-model="order.warranty_status_id"
                    class="select is-medium is-uppercase"
                  >
                    <option :value="0">Brak</option>
                    <option
                      v-for="status in warrantyStatus.filter(
                        (item) => item.is_active
                      )"
                      :key="status.id"
                      :value="status.id"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <!-- 
        <div
          :class="errors['device_hours'] && errors['device_hours'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">Liczba motogodzin</label>
              <span v-if="errors['device_hours'] && errors['device_hours'].length > 0"
                class="help is-danger">{{ errors['device_hours'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="device_hours">
                  <input name="device_hours" type="number" v-model="order.device_hours" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>             
        </div>             
        -->
        <div
          :class="
            errors['service_type_id'] && errors['service_type_id'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_service_type_id_field_label"]
              }}</label>
              <span
                v-if="
                  errors['service_type_id'] &&
                  errors['service_type_id'].length > 0
                "
                class="help is-danger"
                >{{ errors["service_type_id"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="service_type_id">
                  <select
                    name="service_type_id"
                    v-model="order.service_type_id"
                    class="select is-medium is-uppercase"
                  >
                    <option
                      v-for="sType in serviceType.filter(
                        (item) => item.is_active
                      )"
                      :key="sType.id"
                      :value="sType.id"
                    >
                      {{ sType.name }}
                    </option>
                  </select>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['order_service'] && errors['order_service'].length > 0
              ? 'columns is-variable is-3 is-multiline is-mobile v-field v-error '
              : 'columns is-variable is-3 is-multiline is-mobile'
          "
        >
          <div class="column is-9-mobile is-3-tablet">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_is_service_field_label"]
              }}</label>
              <span
                v-if="
                  errors['order_service'] && errors['order_service'].length > 0
                "
                class="help is-danger"
                >{{ errors["order_service"][0] }}</span
              >
            </div>
          </div>
          <div class="column is-2-mobile is-1-tablet">
            <div
              type="button"
              @click="AddField"
              class="button is-dark"
              style="padding: 0px; width: 48px"
            >
              <icon name="plus"></icon>
            </div>
          </div>
          <div class="column is-12-mobile is-8-tablet">
            <div class="field">
              <div
                v-for="(service, i) in order_service_fields"
                :key="i"
                class="control is-medium"
                style="display: flex; gap: 8px; align-items: center"
              >
                <multiselect
                  name="order_service"
                  :value="order_service[i]"
                  @input="servicesOnChange"
                  :options="services"
                  :multiple="false"
                  group-values="data"
                  group-label="category"
                  :group-select="false"
                  placeholder="Wybierz"
                  track-by="name"
                  label="name"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="option__title">{{ props.option.name }}</div>
                    <small
                      >{{ props.option.category }} / {{ props.option.price }} zł
                      <span v-if="props.option.minutes"
                        >({{ props.option.minutes }} minut)</span
                      ></small
                    >
                  </template>
                  <template slot="option" slot-scope="props">
                    <div v-if="props.option.$isLabel">
                      {{ props.option.$groupLabel }}
                    </div>
                    <div v-else>
                      <div class="option__title">{{ props.option.name }}</div>
                      <small
                        >{{ props.option.price }} zł
                        <span v-if="props.option.minutes"
                          >({{ props.option.minutes }} minut)</span
                        ></small
                      >
                    </div>
                  </template>
                </multiselect>
                <div
                  type="button"
                  class="button is-medium"
                  @click="removeOrderService(i)"
                  v-if="
                    order_service[i] && Object.keys(order_service[i]).length > 0
                  "
                >
                  <icon name="trash"></icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['service_price'] && errors['service_price'].length > 0
              ? 'columns is-variable is-3 is-multiline is-mobile v-field v-error '
              : 'columns is-variable is-3 is-multiline is-mobile'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_service_price_field_label"]
              }}</label>
              <span
                v-if="
                  errors['service_price'] && errors['service_price'].length > 0
                "
                class="help is-danger"
                >{{ errors["service_price"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="service_price">
                  <input
                    name="service_price"
                    v-model="order.service_price"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['device_service_work'] &&
            errors['device_service_work'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_device_service_work_field_label"]
              }}</label>
              <span
                v-if="
                  errors['device_service_work'] &&
                  errors['device_service_work'].length > 0
                "
                class="help is-danger"
                >{{ errors["device_service_work"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="device_service_work">
                  <textarea
                    name="device_service_work"
                    v-model="order.device_service_work"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['service_time'] && errors['service_time'].length > 0
              ? 'columns is-variable is-3 is-multiline is-mobile v-field v-error '
              : 'columns is-variable is-3 is-multiline is-mobile'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_service_time_field_label"]
              }}</label>
              <p>00:00:00 (hh:mm:ss)</p>
              <span
                v-if="
                  errors['service_time'] && errors['service_time'].length > 0
                "
                class="help is-danger"
                >{{ errors["service_time"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="service_time">
                  <input
                    name="service_time"
                    placeholder="00:00:00"
                    v-maska="'##:##:##'"
                    type="text"
                    v-model="order.service_time"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['operator_notes'] && errors['operator_notes'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_operator_notes_field_label"]
              }}</label>
              <span
                v-if="
                  errors['operator_notes'] &&
                  errors['operator_notes'].length > 0
                "
                class="help is-danger"
                >{{ errors["operator_notes"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="operator_notes">
                  <textarea
                    name="operator_notes"
                    v-model="order.operator_notes"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <div class="ym-toptitle-box">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <h2 class="title">Protokół serwisowy</h2>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['service_fault'] && errors['service_fault'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_service_fault_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['service_fault'] &&
                    errors['service_fault'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["service_fault"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="service_fault">
                    <textarea
                      name="service_fault"
                      v-model="order.service_fault"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['service_description'] &&
              errors['service_description'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_service_description_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['service_description'] &&
                    errors['service_description'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["service_description"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="service_description">
                    <textarea
                      name="service_description"
                      v-model="order.service_description"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['technical_notes'] && errors['technical_notes'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_technical_notes_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['technical_notes'] &&
                    errors['technical_notes'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["technical_notes"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="technical_notes">
                    <textarea
                      name="technical_notes"
                      v-model="order.technical_notes"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_serial'] && errors['device_serial'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_device_serial_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['device_serial'] &&
                    errors['device_serial'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_serial"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="device_serial">
                    <input
                      name="device_serial"
                      type="text"
                      v-model="order.device_serial"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_area'] && errors['device_area'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_company_full">Liczba ha</label>
                <span
                  v-if="
                    errors['device_area'] && errors['device_area'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_area"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="device_area">
                    <input
                      name="device_area"
                      type="number"
                      v-model="order.device_area"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_bales'] && errors['device_bales'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_company_full">Liczba bali</label>
                <span
                  v-if="
                    errors['device_bales'] && errors['device_bales'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_bales"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="device_bales">
                    <input
                      name="device_bales"
                      type="number"
                      v-model="order.device_bales"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_paid'] && errors['is_paid'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Płatne</label>
                <span
                  v-if="errors['is_paid'] && errors['is_paid'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_paid"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_paid">
                        <input
                          type="radio"
                          name="is_paid"
                          v-model="order.is_paid"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_paid">
                        <input
                          type="radio"
                          name="is_paid"
                          v-model="order.is_paid"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_trade'] && errors['is_trade'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Handel</label>
                <span
                  v-if="errors['is_trade'] && errors['is_trade'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_trade"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_trade">
                        <input
                          type="radio"
                          name="is_trade"
                          v-model="order.is_trade"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_trade">
                        <input
                          type="radio"
                          name="is_trade"
                          v-model="order.is_trade"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_service'] && errors['is_service'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Usługi</label>
                <span
                  v-if="errors['is_service'] && errors['is_service'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_service"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9-tablet is-12-mobile">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_service">
                        <input
                          type="radio"
                          name="is_service"
                          v-model="order.is_service"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_service">
                        <input
                          type="radio"
                          name="is_service"
                          v-model="order.is_service"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['order_technical'] && errors['order_technical'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Serwisanci asystujący</label>
                <span
                  v-if="
                    errors['order_technical'] &&
                    errors['order_technical'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["order_technical"][0] }}</span
                >
              </div>
            </div>
            <div class="column is-9">
              <div
                v-if="order_technical.length > 0"
                style="margin-bottom: 16px"
              >
                <b-field
                  v-for="technical in order_technical"
                  :key="technical.id"
                  grouped
                >
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="technical.firstname"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="technical.lastname"
                    /><!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> --></b-field
                  >
                  <div class="ym-tableItemWrap">
                    <div
                      class="button"
                      @click="removeOrderTechnical(technical.id)"
                    >
                      <icon name="x"></icon>
                    </div>
                    <div class="button" @click="editOrderTechnical(technical)">
                      <icon name="check"></icon>
                    </div>
                  </div>
                </b-field>
              </div>

              <b-field grouped>
                <b-field expanded>
                  <input
                    name="order_technical_firstname"
                    type="text"
                    v-model="order_technical_item.firstname"
                    placeholder="Imię"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded>
                  <input
                    name="order_technical_lastname"
                    type="text"
                    v-model="order_technical_item.lastname"
                    placeholder="Nazwisko"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addOrderTechnical"
                    class="button is-medium is-dark"
                    v-if="online"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider name="order_technical">
              <input
                type="hidden"
                name="order_technical"
                v-model="order_technical"
              />
            </ValidationProvider>
          </div>
          <div
            :class="
              errors['order_commute'] && errors['order_commute'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Dojazd</label>
                <span
                  v-if="
                    errors['order_commute'] &&
                    errors['order_commute'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["order_commute"][0] }}</span
                >
              </div>
            </div>
            <div class="column is-12">
              <!-- <div style="display: flex; align-items: center; justify-content: flex-end;">
                  <div class="ym-label">
                    <label for="f_dm_company">{{ commuteDistanceSummary() }}</label>
                  </div>
              </div> -->
              <div v-if="order_commute.length > 0" style="margin-bottom: 16px">
                <b-field
                  v-for="commute in order_commute"
                  :key="commute.id"
                  grouped
                >
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="date"
                      v-model="commute.commuted_at"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="commute.city_from"
                    /><!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> --></b-field
                  >
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="commute.city_to"
                    /><!-- <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> --></b-field
                  >
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="commute.distance"
                  /></b-field>
                  <div class="ym-tableItemWrap">
                    <div class="button" @click="removeCommuteItem(commute.id)">
                      <icon name="x"></icon>
                    </div>
                    <div class="button" @click="editCommuteItem(commute)">
                      <icon name="check"></icon>
                    </div>
                  </div>
                </b-field>
              </div>
              <b-field grouped>
                <b-field expanded>
                  <b-datepicker
                    :mobile-native="false"
                    name="order_commute_date"
                    v-model="order_commute_item.date"
                    placeholder="Data"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    position="is-top-right"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
                <b-field expanded>
                  <input
                    name="order_commute_from"
                    type="text"
                    v-model="order_commute_item.city_from"
                    placeholder="Dojazd z"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <!-- <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_commute_item.time_from" placeholder="Od"></b-timepicker>
                  </b-field> -->
                <b-field expanded>
                  <input
                    name="order_commute_to"
                    type="text"
                    v-model="order_commute_item.city_to"
                    placeholder="Dojazd do"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <!-- <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_commute_item.time_to" placeholder="Do"></b-timepicker>
                  </b-field> -->
                <b-field expanded>
                  <input
                    name="order_commute_distance"
                    type="number"
                    v-model="order_commute_item.distance"
                    placeholder=" km"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addCommuteItem"
                    class="button is-medium is-dark"
                    v-if="online"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider name="order_commute">
              <input
                type="hidden"
                name="order_commute"
                v-model="order_commute"
              />
            </ValidationProvider>
          </div>
          <div
            :class="
              errors['order_work'] && errors['order_work'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Godziny pracy</label>
                <span
                  v-if="errors['order_work'] && errors['order_work'].length > 0"
                  class="help is-danger"
                  >{{ errors["order_work"][0] }}</span
                >
              </div>
            </div>
            <div class="column is-12">
              <!-- <div style="display: flex; align-items: center; justify-content: flex-end;">
                  <div class="ym-label">
                    <label for="f_dm_company" v-if="order && order.work_workers_time !== `00:00`">{{ order.work_workers_time }}</label>
                  </div>
              </div> -->
              <div v-if="order_work.length > 0" style="margin-bottom: 16px">
                <b-field v-for="work in order_work" :key="work.id" grouped>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="date"
                      v-model="work.worked_at"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="work.time_from"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="work.time_to"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="work.workers"
                  /></b-field>
                  <div class="ym-tableItemWrap">
                    <div class="button" @click="removeWorkItem(work.id)">
                      <icon name="x"></icon>
                    </div>
                    <div class="button" @click="editWorkItem(work)">
                      <icon name="check"></icon>
                    </div>
                  </div>
                </b-field>
              </div>
              <b-field grouped>
                <b-field expanded>
                  <b-datepicker
                    :mobile-native="false"
                    name="order_work_date"
                    v-model="order_work_item.date"
                    placeholder="Data"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    position="is-top-right"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
                <b-field expanded>
                  <b-timepicker
                    size="is-medium"
                    v-model="order_work_item.time_from"
                    placeholder="Od"
                  ></b-timepicker>
                </b-field>
                <b-field expanded>
                  <b-timepicker
                    size="is-medium"
                    v-model="order_work_item.time_to"
                    placeholder="Do"
                  ></b-timepicker>
                </b-field>
                <b-field expanded>
                  <input
                    name="order_work_distance"
                    type="text"
                    v-model="order_work_item.workers"
                    placeholder="Liczba serwisantów"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addWorkItem"
                    class="button is-medium is-dark"
                    v-if="online"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider name="order_work">
              <input type="hidden" name="order_work" v-model="order_work" />
            </ValidationProvider>
          </div>
          <div class="columns is-mobile is-variable is-3 is-multiline">
            <div class="column ym-column totop is-12">
              <div class="ym-label">
                <label for="f_dm_company">{{
                  dictionary["table_order_image_field_label"]
                }}</label>
              </div>
            </div>
            <div
              v-show="online"
              class="column is-12"
              style="margin-bottom: 24px"
            >
              <b-upload
                @input="handleFileChange(file)"
                accept="image/*"
                v-model="file"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Dodaj {{ dictionary["table_order_image_field_label"] }}
                    </p>
                  </div>
                </section>
              </b-upload>
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <span
                  v-for="(file, index) in files"
                  :key="index"
                  class="column is-4"
                >
                  <img :src="file.id ? file.url : file.image_stream" />
                  <p v-if="file.id" style="margin-bottom: 8px">
                    {{ file.content }}
                  </p>
                  <input
                    v-else
                    name="file_content"
                    v-model="file.content"
                    class="input is-medium"
                    style="margin-bottom: 8px"
                  />
                  <div class="buttons">
                    <b-button
                      @click="removeOrderImage(file.id, index)"
                      type="is-dark"
                      expanded
                    >
                      <span
                        >Usuń
                        {{ dictionary["table_order_image_field_label"] }}</span
                      >
                    </b-button>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="confirmButtons" v-if="!online">
        <div class="button is-medium is-fullwidth is-error">
          Brak połączenia z internetem
        </div>
      </div>
    </ValidationObserver>
    <b-modal
      v-model="isModalActive"
      v-if="calendar && calendar !== '' && calendar.is_active"
      :width="720"
      scroll="keep"
    >
      <div class="card">
        <div class="card-content">
          <div class="content content-iframe" v-html="calendar.content"></div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";
import Multiselect from "vue-multiselect";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ServiceForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
    Multiselect,
  },
  data: function () {
    return {
      warrantyStatus: [],
      services: [],
      order_service: [],
      order_service_fields: [{}],
      serviceType: [],
      order_commute: [],
      order_commute_item: {
        date: new Date(),
        city_from: null,
        city_to: null,
        distance: null,
      },
      order_work: [],
      order_work_item: {
        date: new Date(),
        time_from: null,
        time_to: null,
        workers: 1,
      },
      file: null,
      files: [],
      order_technical: [],
      order_technical_item: {
        firstname: null,
        lastname: null,
      },
      online: navigator.onLine,
      isModalActive: false,
    };
  },
  watch: {
    "order.service_price"(newVal) {
      Number(newVal) < 0
        ? (this.order.service_price = Number(newVal) * -1)
        : Number(newVal);
    },
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      isDisabledSidebar: "disabledSidebar",
      order: "orders/order",
      customer: "orders/customer",
      device: "orders/device",
      technician: "orders/technician",
      operator: "orders/operator",
      user: "auth/user",
      calendar: "calendar",
    }),
    isNew() {
      return this.id === 0 ? true : false;
    },
    customerInfo() {
      const tpl = `${this.customer.name}
${this.customer.address}
${this.customer.postcode ? this.customer.postcode : ""} ${
        this.customer.city ? this.customer.city : ""
      }
NIP ${this.customer.nip}
Telefon ${this.customer.phone}`;
      return tpl;
    },
    repared_at: {
      get() {
        let value = this.$store.state.orders.order.repared_at
          ? new Date(this.$store.state.orders.order.repared_at)
          : new Date();
        const editedOrder = { ...this.order, repared_at: value };
        this.$store.commit("orders/setOrder", editedOrder);
        return value;
      },
      set(value) {
        const editedOrder = { ...this.order, repared_at: value };
        this.$store.commit("orders/setOrder", editedOrder);
      },
    },
  },
  methods: {
    ...mapActions({
      getOrder: "orders/getOrder",
      postOrder: "orders/postOrder",
      disabledSidebar: "setDisabledSidebar",
      postCommuteItemsCall: "orders/postCommuteItems",
      getCommuteItemsCall: "orders/getCommuteItems",
      editCommuteItemsCall: "orders/editCommuteItems",
      removeCommuteItemsCall: "orders/removeCommuteItems",
      postWorkItemsCall: "orders/postWorkItems",
      getWorkItemsCall: "orders/getWorkItems",
      editWorkItemsCall: "orders/editWorkItems",
      removeWorkItemsCall: "orders/removeWorkItems",
      getOrderImageCall: "orders/getOrderImage",
      postOrderImage: "orders/postOrderImage",
      removeOrderImageCall: "orders/removeOrderImage",
      getOrderTechnicalCall: "orders/getOrderTechnical",
      postOrderTechnicalCall: "orders/postOrderTechnical",
      editOrderTechnicalCall: "orders/editOrderTechnical",
      removeOrderTechnicalCall: "orders/removeOrderTechnical",
      getServiceTypeCall: "orders/serviceType",
      getWarrantyStatusCall: "orders/warrantyStatus",
      getServiceItemsCall: "orders/getServiceItems",
      getOrderServiceItemsCall: "orders/getOrderServiceItems",
      postServiceItemsCall: "orders/postServiceItems",
      removeOrderServiceCall: "orders/removeOrderService",
    }),
    AddField() {
      this.order_service_fields.push({});
    },
    servicesOnChange(value) {
      if (isNaN(Number(this.order.service_price))) this.order.service_price = 0;
      this.order.service_price =
        Number(this.order.service_price) + Number(value.price);
      this.order_service.push(value);
      if (!this.isNew) {
        let payload = {
          order_id: this.$route.params.id,
          category: value.category,
          name: value.name,
          price: value.price,
          minutes: value.minutes,
        };
        this.postServiceItemsCall(payload);
      }
    },
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    onchange() {
      this.online = navigator.onLine;
      this.$emit(this.online ? "online" : "offline");
    },
    cancelAdd() {
      if (this.isDisabledSidebar) {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: "Czy na pewno chcesz przerwać",
            content: "",
            cancel: "Nie",
            ymHasIcon: true,
            ymIcon: "x",
            submit: `<span class="mal50i">Przerwij</span>`,
          },
        });
      }
    },
    async uploadImages() {
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index];
        if (!file.id) await this.postOrderImage(file);
      }
    },
    async onSubmit() {
      await this.uploadImages();
      const orderPost = {
        ...this.order,
        technical_id: this.user.id,
        operator_id: this.operator.id,
        customer_id: this.customer.id,
        device_serial: this.device.serial,
        device_type: this.device.type,
        device_name: this.device.name,
      };

      await this.postOrder({ order: orderPost, id: this.$route.params.id })
        .then((resp) => {
          this.disabledSidebar(false);
          if (this.isNew) {
            this.postServiceItems(resp.data.id);
          }
          this.$router.push({
            name: "ServiceDetails",
            params: { id: resp.data.id },
          });
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    chooseCustomer() {
      this.$store.dispatch("orders/setSelectACustomer", true).then(() => {
        this.$router.push({ name: "Customer" });
      });
    },
    chooseTechnician() {
      this.$store.dispatch("orders/setSelectATechnician", true).then(() => {
        this.$router.push({ name: "ServiceTechnician" });
      });
    },
    chooseOperator() {
      this.$store.dispatch("orders/setSelectAOperator", true).then(() => {
        this.$router.push({ name: "Operators" });
      });
    },
    chooseDevice() {
      this.$store.dispatch("orders/setSelectADevice", true).then(() => {
        this.$router.push({ name: "Machinery" });
      });
    },
    clearDeviceAddress() {
      this.order.device_address = "";
    },
    getCommuteItems() {
      this.getCommuteItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_commute = resp))
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addCommuteItem() {
      const isEmpty = !Object.values(this.order_commute_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola dojazdu");
      } else {
        let item = {
          ...this.order_commute_item,
          order_id: this.$route.params.id,
        };
        this.postCommuteItemsCall({ item })
          .then((resp) => {
            this.getCommuteItems();
            this.order_commute.push(resp.data);
            this.$refs.observer.refs.order_commute.validate();
          })
          .catch(() => {
            this.order_commute = {};
          });
        this.order_commute_item = {
          date: new Date(),
          city_from: null,
          city_to: null,
          distance: null,
        };
      }
    },
    editCommuteItem(item) {
      this.editCommuteItemsCall(item)
        .then(() => {
          this.getCommuteItems();
        })
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeCommuteItem(id) {
      this.removeCommuteItemsCall({ id })
        .then(() => {
          this.getCommuteItems();
        })
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWorkItems() {
      this.getWorkItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_work = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addWorkItem() {
      const isEmpty = !Object.values(this.order_work_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola godzin pracy");
      } else {
        let item = {
          ...this.order_work_item,
          order_id: this.$route.params.id,
        };
        this.postWorkItemsCall({ item })
          .then((resp) => {
            this.getWorkItems();
            this.order_work.push(resp.data);
            this.$refs.observer.refs.order_work.validate();
          })
          .catch((error) => {
            this.order_work = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: "is-bottom",
              type: "is-danger",
            });
          });
        this.order_work_item = {
          date: new Date(),
          time_from: null,
          time_to: null,
          workers: null,
        };
      }
    },
    editWorkItem(item) {
      this.editWorkItemsCall(item)
        .then(() => {
          this.getWorkItems();
        })
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeWorkItem(id) {
      this.removeWorkItemsCall({ id })
        .then(() => {
          this.getWorkItems();
        })
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    commuteDistanceSummary() {
      if (this.order_commute.length > 0) {
        return this.order_commute.reduce((a, b) => +a + +b.distance, 0) + " km";
      }
    },
    workMinutesSummary() {
      if (this.order_work.length > 0) {
        return (
          this.order_work.reduce((a, b) => +a + +b.workers_minutes, 0) +
          " minut"
        );
      }
    },
    compressImage(base64) {
      const canvas = document.createElement("canvas");
      const img = document.createElement("img");
      return new Promise((resolve, reject) => {
        let imageCompressLevelTemp = 0.7;
        img.onload = function () {
          let width = img.width;
          let height = img.height;
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL("image/jpeg", imageCompressLevelTemp));
        };
        img.onerror = function (err) {
          reject(err);
        };
        img.src = base64;
      });
    },
    handleFileChange(file) {
      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
      getBase64(file).then((data) => {
        this.compressImage(data).then((compressed) => {
          let payload = {
            order_id: this.order.id,
            image_stream: compressed,
            content: "",
          };
          this.files.push(payload);
          this.file = null;
        });
        // this.postOrderImage(payload)
        //   .then((resp) => {
        //     this.disabledSidebar(false);
        //     this.files.push(resp.data);
        //   })
        //   .catch((error) => {
        //     this.order = {};
        //     this.$buefy.toast.open({
        //       duration: 5000,
        //       message: error.data.error_description,
        //       position: "is-bottom",
        //       type: "is-danger",
        //     });
        //   });
      });
    },
    getOrderImage() {
      this.getOrderImageCall({ id: this.$route.params.id })
        .then((resp) => (this.files = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeOrderImage(id, index) {
      if (id) {
        this.removeOrderImageCall({ id })
          .then(() => {
            this.getOrderImage();
          })
          .catch((error) => {
            this.order_work = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      } else {
        this.files.splice(index, 1);
      }
    },
    getOrderTechnical() {
      this.getOrderTechnicalCall({ id: this.$route.params.id })
        .then((resp) => (this.order_technical = resp))
        .catch((error) => {
          this.order_technical = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addOrderTechnical() {
      const isEmpty = !Object.values(this.order_technical_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola");
      } else {
        let item = {
          ...this.order_technical_item,
          order_id: this.$route.params.id,
        };
        this.postOrderTechnicalCall({ item })
          .then((resp) => {
            this.getOrderTechnical();
            this.order_technical.push(resp.data);
            this.$refs.observer.refs.order_technical.validate();
          })
          .catch((error) => {
            this.order_technical = [];
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: "is-bottom",
              type: "is-danger",
            });
          });
        this.order_technical_item = {
          firstname: null,
          lastname: null,
        };
      }
    },
    editOrderTechnical(item) {
      this.editOrderTechnicalCall(item)
        .then(() => {
          this.getOrderTechnical();
        })
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeOrderTechnical(id) {
      this.removeOrderTechnicalCall({ id })
        .then(() => {
          this.getOrderTechnical();
        })
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getServiceType() {
      this.getServiceTypeCall()
        .then((resp) => (this.serviceType = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrderServiceItems(id) {
      this.getOrderServiceItemsCall(id)
        .then((resp) => {
          this.order_service = resp;
          if (this.order_service.length > 1) {
            for (
              let index = 0;
              index < this.order_service.length - 1;
              index++
            ) {
              this.order_service_fields.push({});
            }
          }
        })
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getServiceItems() {
      this.getServiceItemsCall()
        .then((resp) => {
          const groupByKey = (data, key) =>
            Object.values(
              data.reduce((res, item) => {
                const value = item[key];
                const existing = res[value] || { [key]: value, data: [] };
                return {
                  ...res,
                  [value]: {
                    ...existing,
                    data: [...existing.data, item],
                  },
                };
              }, {})
            );
          const actives = resp.filter((item) => item.is_active);
          this.services = groupByKey(actives, "category");
        })
        .catch((error) => {
          this.services = [];
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    postServiceItems(id) {
      for (let index = 0; index < this.order_service.length; index++) {
        const element = this.order_service[index];
        let payload = {
          order_id: id,
          category: element.category,
          name: element.name,
          price: element.price,
          minutes: element.minutes,
        };
        this.postServiceItemsCall(payload);
      }
    },
    removeOrderService(idx) {
      if (!this.isNew) {
        this.removeOrderServiceCall({
          id: this.order_service[idx].id,
          order_id: parseInt(this.$route.params.id),
        });
      }
      this.order.service_price =
        Number(this.order.service_price) -
        Number(this.order_service[idx].price);
      this.order_service.splice(idx, 1);
      this.order_service_fields.splice(idx, 1);
      if (this.order_service_fields.length === 0) {
        this.order_service_fields.push({});
      }
    },
  },
  mounted() {
    this.disabledSidebar(true);
    //  && Object.keys(this.order).length === 0 && this.order.constructor === Object
    if (
      this.isNew &&
      Object.keys(this.customer).length === 0 &&
      Object.keys(this.device).length === 0 &&
      Object.keys(this.operator).length === 0
    ) {
      this.$store.commit("orders/setOrder", {});
      this.$store.commit("orders/setSelectACustomer", false);
      this.$store.commit("orders/setCustomer", {});
      this.$store.commit("orders/setSelectATechnician", false);
      this.$store.commit("orders/setTechnician", {});
      this.$store.commit("orders/setSelectAOperator", false);
      this.$store.commit("orders/setOperator", {});
      this.$store.commit("orders/setSelectADevice", false);
      this.$store.commit("orders/setDevice", {});
    }
    if (this.order.status_id === 30 || this.order.status_id === 40) {
      this.getCommuteItems();
      this.getWorkItems();
      this.getOrderImage();
      this.getOrderTechnical();
    }
    this.getServiceType();
    this.getWarrantyStatus();
    this.getServiceItems();
    if (this.$route.params.id) {
      this.getOrderServiceItems(parseInt(this.$route.params.id));
    }
    window.addEventListener("online", this.onchange);
    window.addEventListener("offline", this.onchange);
    this.onchange();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onchange);
    window.removeEventListener("offline", this.onchange);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.control + .control {
  margin-top: 8px;
}
.select {
  width: 100%;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 8px;
}
.is-info {
  margin-top: 1rem;
  background-color: #3e8ed0;
  color: #fff;
  padding: 1rem;
}
</style>

<style>
.content-iframe {
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.content-iframe iframe,
.content-iframe object,
.content-iframe embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 851px) {
  .content-iframe {
    min-width: 80vw;
    min-height: calc(100vh - 210px);
  }
}
</style>
